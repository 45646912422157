<template>
  <!--财务统计-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="90px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="客户名称:">
                    <el-input
                        clearable
                        v-model="where.facilitator_name"
                        placeholder="订单来源客户/订单来源服务商"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                       style="width: 100%!important;"
                       v-model="where.time"
                       value-format="yyyy-MM-dd"
                       type="daterange"
                       range-separator="至"
                       start-placeholder="开始日期"
                       end-placeholder="结束日期">
                    </el-date-picker>
                    <!-- <DatePicker v-model="where.time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/> -->
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <!--<el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="TowithdrawalApplication()">-->
                <!--  <span style="vertical-align: middle">提现申请</span>-->
                <!--</el-button>-->
                <!--<span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 30px;">累计提现：</span>-->
                <!--<span style="font-size: 18px;font-weight: 700;color: #fea837;">2000.00</span>-->
                <!--<span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">提现处理中：</span>-->
                <!--<span style="font-size: 18px;font-weight: 700;color: #4E4E4E;">0</span>-->
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <el-table
                :data="List"
                v-loading="loading"
                :header-cell-style="headerCellStyle"
                style="width: 100%;">
              <el-table-column
                  align="center"
                  prop="id"
                  label="编号"
                  width="150">
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="client_name"
                  label="发包方">
              </el-table-column>


              <el-table-column v-for="(item,idx) in header" :key="idx"  :label="item.title" align="center">
                <el-table-column
                    align="center"
                    label="订单来源结算">
                  <template slot-scope="scope">
                    <span>{{scope.row.data[idx].client_price}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="支付下游全款">
                  <template slot-scope="scope">
                    <span>{{scope.row.data[idx].contract_price}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="支付客单价">
                  <template slot-scope="scope">
                    <span>{{scope.row.data[idx].customer_price}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="毛利">
                  <template slot-scope="scope">
                    <span>{{scope.row.data[idx].gross_margin}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="毛利率">
                  <template slot-scope="scope">
                    <span>{{scope.row.data[idx].gross_profit_rate}}</span>
                  </template>
                </el-table-column>
              </el-table-column>

              <!--<el-table-column label="困境救援" align="center">-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="订单来源结算">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付下游全款">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付客单价">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利率">-->
              <!--  </el-table-column>-->
              <!--</el-table-column>-->

              <!--<el-table-column label="拖车+地库" align="center">-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="订单来源结算">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付下游全款">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付客单价">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利率">-->
              <!--  </el-table-column>-->
              <!--</el-table-column>-->

              <!--<el-table-column label="搭电救援" align="center">-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="订单来源结算">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付下游全款">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付客单价">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利率">-->
              <!--  </el-table-column>-->
              <!--</el-table-column>-->

              <!--<el-table-column label="换胎救援" align="center">-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="订单来源结算">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付下游全款">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付客单价">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利率">-->
              <!--  </el-table-column>-->
              <!--</el-table-column>-->

              <!--<el-table-column label="充气救援" align="center">-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="订单来源结算">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付下游全款">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="支付客单价">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利">-->
              <!--  </el-table-column>-->
              <!--  <el-table-column-->
              <!--      align="center"-->
              <!--      prop="id"-->
              <!--      label="毛利率">-->
              <!--  </el-table-column>-->
              <!--</el-table-column>-->

            </el-table>

            <!--<el-pagination-->
            <!--    style="text-align: center; margin-top: 20px"-->
            <!--    layout="total, sizes, prev, pager, next, jumper"-->
            <!--    :total="total"-->
            <!--    :page-size="where.limit"-->
            <!--    :current-page="where.page"-->
            <!--    @size-change="ChangeSize"-->
            <!--    @current-change="currentChange"-->
            <!--    background>-->
            <!--</el-pagination>-->

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <!--<div class="dialog">-->
      <!--  <el-dialog-->
      <!--      title="列表设置"-->
      <!--      :visible.sync="ListSetupShow"-->
      <!--      width="60%"-->
      <!--      center-->
      <!--      :before-close="handleClose">-->
      <!--  <span>-->
      <!--     <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>-->
      <!--  </span>-->
      <!--  </el-dialog>-->
      <!--</div>-->
    </div>

  </div>
</template>

<script>

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {client_statistics} from "@/api/finance";

export default {
  // 组件生效
  components: {
    PackageSortElement,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        // page: 1, // 当前页数
        // limit: 10 // 每页显示条目个数
      },
      // 表头数据
      header:[],
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,

      // 列表设置弹窗
      ListSetupShow:false,
    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      client_statistics(this.where).then(async (res) => {
        console.log(res)
        // this.total = res.data.count;
        this.header = res.data.header;
        this.List = res.data.clientList;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择日期
    datechange(e){
      this.where.time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //跳转到提现申请
    TowithdrawalApplication(){
      this.$router.push('/withdrawalApplication');
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
